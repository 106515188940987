<template>

    <section class="">
        <b-card
            v-if="statistics"
            no-body
            class="card-statistics"
        >
            <clients-statistics :data="statistics" />
        </b-card>

        <b-card v-for="business in businesses" :key="business.id">
            <business-type-clients :data="business" />
        </b-card>
    </section>
</template>

<script>
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import {
        BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import store from '@/store'
    // import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
    import BusinessTypeClients from './BusinessTypeClients.vue'
    import ClientsStatistics from './ClientsStatistics.vue'
    // import { useEcommerceUi } from '../useEcommerce'
    import clientsStoreModule from '@/views/cromis/clients/clientsStoreModule'

    export default {
        props: {},
        components: {
            // BSV
            BCard,
            BCardBody,
            BRow,
            BCol,
            BImg,
            BCardText,
            BLink,
            BButton,
            BDropdown,
            BDropdownItem,
            BAlert,

            // SFC
            BusinessTypeClients,
            ClientsStatistics,
        },
        directives: {},

        setup(props, context) {
            const businesses = ref([])

            const statistics = ref([
                { color: 'light-warning', customClass: 'mb-2 mb-xl-0', icon: 'TruckIcon', title: '', subtitle: 'Buyers', count: 0, growers: 0, type: 0, },
                { color: 'light-primary', customClass: 'mb-2 mb-xl-0', icon: 'TrendingUpIcon', title: '', subtitle: 'Unions', count: 0, growers: 0, type: 2, },
                { color: 'light-success', customClass: 'mb-2 mb-xl-0', icon: 'UsersIcon', title: '', subtitle: 'Societies', count: 0, growers: 0, type: 1 },
            ])

            const CROMIS_STORE_MODULE_NAME = 'cromis-client'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, clientsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-client/list')
                    .then(response => {
                        businesses.value = response.data.businesses
                        
                        businesses.value.map(business => {
                            business.clients.map(client => {
                                if(client.type === 0)
                                    statistics.value[0].count += 1

                                if(client.type === 2)
                                    statistics.value[1].count += 1

                                if(client.type === 1){
                                    statistics.value[2].count += 1
                                    statistics.value[2].growers += client.growersCount
                                }
                            })
                        })

                        statistics.value.map(element => {
                            element.title = ''+element.count
                        })

                    })
                    .catch(error => {
                        console.log('got error', error)
                    })
            })

            return {
                businesses,
                statistics,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
    @import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>